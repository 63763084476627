<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">构建个人工作看板</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        通过活动分解，将所有职责细分至不可拆分的最小颗粒度，然后通过软件平台进行分工，推送至个人工作看板
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        岗位职责说明书不能表达全部的工作内容
        <br />
        要从数十份流程文件中寻找与自己工作岗位相关的内容是大海捞针
        <br />
        所以日常工作凭经验、靠惯性
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        辅导活动分解，将所有工作分解至最小单位，然后进行分工
        <br />
        提供软件平台，将分解的内容在平台上建立个人工作看板
        <br />
        可通过PC端或手机端随时查询自己岗位的工作内容和标准
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>